<template>
    <Top back :notifications="false" />
    <v-container v-if="notifications.length > 0" class="flex-column justify-start tw-flex tw-gap-4">
        <v-row align="center" justify="center" class="fill-width h-auto justify-start align-content-start">
            <v-col v-for="(notification, i) in notifications" :key="i" cols="12">
                <NotificationCard @get-notifications="getNotifications" :notification="notification" />
            </v-col>
        </v-row>
        <div v-intersect.quiet="onIntersect"></div>
    </v-container>
    <div v-else class="tw-h-full tw-flex tw-justify-center tw-items-center">
        <p class="tw-text-gray_darker">Sem notificações</p>
    </div>
</template>

<script setup lang="ts">
    import { ref, onMounted } from 'vue';
    import Top from '@/views/Layout/Top.vue';
    import NotificationCard from './Layout/NotificationCard.vue';
    import axios from '@/plugins/axios';
    import qs from 'qs';
    import { useUserStore } from '@/store/user';

    const notifications = ref([]);

    const userStore = useUserStore();

    const query = ref({
        sort: ['read:asc', 'danger:desc', 'createdAt:desc'],
        filters: {
            user: {
                id: {
                    $eq: userStore.getUser.id,
                },
            },
        },
        pagination: {
            page: 1,
            pageSize: 25,
            pageCount: 1,
        },
    });

    const getNotifications = async () => {
        const res = await axios.get(`notifications/?${qs.stringify(query.value)}`);
        notifications.value = query.value.pagination.page == 1 ? res.data.data : [...notifications.value, ...res.data.data];
        query.value.pagination.pageCount = res.data.meta.pagination.pageCount;
    };

    const onIntersect = (isIntersecting: any, entries: any, observer: any) => {
        if (isIntersecting && query.value.pagination.page < query.value.pagination.pageCount) {
            query.value.pagination.page++;
            getNotifications();
        }
    };

    onMounted(() => {
        getNotifications();
    });
</script>

<style scoped></style>
