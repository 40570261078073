<template>
    <Top :back="true" :notifications="false" :absolute="true" :reverse="false" />
    <div class="tw-relative tw-mb-14">
        <img class="tw-object-bottom tw-w-full tw-max-h-28 tw-object-cover -tw-translate-y-0.5" src="../../assets/svg/header_profile.svg" alt="" />
        <img
            class="tw-rounded-full tw-aspect-square tw-object-cover tw-absolute tw-w-28 tw-bottom-0 tw-left-1/2 tw-translate-y-[40%] -tw-translate-x-1/2 tw-outline tw-outline-[0.3rem] tw-outline-primary tw-bg-gray_lightest"
            :src="image"
            alt="" />
    </div>
    <v-container class="flex-column justify-start tw-relative tw-flex tw-flex-1" fluid>
        <v-row align="start" class="fill-width justify-center tw-bg-white tw-pb-4 !tw-flex-shrink-0 !tw-flex-grow-0" no-gutters>
            <v-col cols="11"><h1 class="tw-text-2xl text-black tw-font-bold tw-text-center">{{reading.control_point.water_network.infrastructure.name}}</h1></v-col>
            <v-col cols="11" class="mt-2">
                <h1 class="tw-text-xl text-black tw-text-center">{{reading.control_point.water_network.name}}</h1>
            </v-col>
            <v-col cols="11" class="mt-2">
                <div class="tw-w-fit tw-m-auto tw-bg-gray tw-px-6 tw-py-1 tw-rounded-md">
                    <p class="tw-w-fit tw-text-sm tw-text-primary tw-font-semibold">{{reading.control_point.name}}</p>
                </div>
            </v-col>
        </v-row>
        <Cold :idReadingSet="idReadingSet" v-if="reading.control_point.water_network.type == 'fria'" :readingtype="reading.reading_type" />
        <Hot :idReadingSet="idReadingSet" v-else-if="reading.control_point.water_network.type == 'quente'" :readingtype="reading.reading_type" />
        <Pool :idReadingSet="idReadingSet" v-else-if="reading.control_point.water_network.type == 'piscina'" :readingtype="reading.reading_type" />
    </v-container>
    <!--  <v-container class="flex-column justify-center px-8 pb-16 tw-flex tw-flex-1 align-content-end" fluid>
        <v-row align="start" class="fill-width h-auto justify-center align-content-end">
            <v-col cols="5" class="tw-text-center">
                <v-btn variant="tonal" block rounded="lg" class="text-none bg-primary"> Concluir</v-btn>
            </v-col>
        </v-row>
    </v-container> -->
</template>

<script setup lang="ts">
    import Top from '../Layout/Top.vue';
    import Hot from './Forms/Hot.vue';
    import Cold from './Forms/Cold.vue';
    import Pool from './Forms/Pool.vue';
    import axios from '@/plugins/axios';
    import { useRoute } from 'vue-router';
    import { onMounted, ref, computed } from 'vue';
    import useMedia from '@/composables/useMedia';

    const $route = useRoute();
    const idReadingSet = ref($route.params.id);

    const reading = ref({control_point: {picture: null, name: '', water_network: {name: '',type: '', infrastructure: {name: ''}}}, reading_type: ''});

    const getReadingSet = async () => {
        const res = await axios.get(`/reading-sets/${$route.params.id}?populate=control_point.picture,control_point,control_point.water_network,control_point.water_network.infrastructure`);
        console.log(res.data.data);
        reading.value = res.data.data;

    }

    const image = computed(() => {
        return useMedia(reading.value.control_point.picture, 'control-point').large;
    })

    onMounted(() => {
        getReadingSet();
    })
</script>

<style scoped></style>
