<template>
    <v-container ref="topBar" class="tw-bg-primary tw-sticky tw-top-0 tw-z-10">
        <div :class="{ 'tw-absolute': absolute, 'tw-z-10': absolute }" class="tw-w-full tw-text-white tw-flex tw-justify-end tw-items-center">
            <h1 v-if="title" class="tw-text-white tw-mr-auto tw-text-lg tw-font-bold">{{ title }}</h1>

            <div v-if="create" @click="goToCreate" class="tw-w-fit tw-rounded-full tw-bg-gray tw-ml-1 tw-p-2"><img src="/icons/plus.svg" alt="" /></div>
            <img @click="goBack" v-if="props.back" class="tw-mr-auto tw-rotate-180" src="/icons/arrow.svg" alt="" />
            <div v-if="props.date" class="tw-w-fit tw-border-white tw-border-[1px] tw-rounded-full tw-px-3 tw-py-[0.5]">
                <span class="tw-text-xs">{{ props.date }}</span>
            </div>
            <v-icon @click="reload" class="tw-right-4" role="img">mdi-refresh</v-icon>
            <v-badge v-if="props.notifications" :model-value="notificationsNumber > 0" color="error" :content=notificationsNumber>
                <v-icon @click="goToNotifications" v-if="props.notifications" role="img">mdi-bell-outline</v-icon>
            </v-badge>
            <v-btn v-if="edit" @click="setEditing" class="text-primary" density="comfortable" size="32" icon="mdi-pencil"></v-btn>
        </div>
    </v-container>
</template>

<script setup lang="ts">
    import { useAppStore } from '@/store/app';
    import { ref } from 'vue';
    import { onMounted } from 'vue';
    import { useRouter } from 'vue-router';
    import { useRoute } from 'vue-router';
    import type { VContainer } from 'vuetify/lib/components/index.mjs';
    const $router = useRouter();
    const $route = useRoute();
    const appStore = useAppStore();
    import axios from '@/plugins/axios';
    import qs from 'qs';
    import { useUserStore } from '@/store/user';

    let notificationsNumber = ref(0);

    const userStore = useUserStore();

    const query = ref({
        sort: ['read:asc', 'createdAt:desc'],
        filters: {
            user: {
                id: {
                    $eq: userStore.getUser.id,
                },
            },
            read: {
                $eq: false,
            },
        },
        pagination: {
            page: 1,
            pageSize: 25,
            pageCount: 1,
        },
    });

    const getNotifications = async () => {
        const res = await axios.get(`notifications/?${qs.stringify(query.value)}`);
        notificationsNumber.value = res.data.meta.pagination.total;
    };

    const props = defineProps({
        reverse: {
            type: Boolean,
            default: true,
        },
        back: {
            type: Boolean,
            default: false,
        },
        title: {
            type: String,
            required: false,
        },
        notifications: {
            type: Boolean,
            default: true,
        },
        absolute: {
            type: Boolean,
            default: true,
        },
        create: {
            type: Boolean,
            default: false,
        },
        edit: {
            type: Boolean,
            default: false,
        },
        date: {
            type: String,
        },
    });

    const goBack = () => {
        $router.go(-1);
    };

    const goToCreate = () => {
        $router.push({ name: $route.meta.createPage as string });
    };

    const topBar = ref<VContainer | null>(null);

    const goToNotifications = () => {
        $router.push({ name: 'Notifications' });
    };

    const reload = () => {
        $router.go(0);
    };

    function setEditing() {
        appStore.setEditing(true);
    }

    onMounted(() => {
        const height = topBar.value?.$el?.offsetHeight ?? 0;
        document.documentElement.style.setProperty('--topBarHeight', `${height}px`);
        getNotifications();
    });
</script>

<style scoped></style>
