<template>
    <!-- <v-container class="bg-primary_DEFAULT px-12 py-8 tw-h-72" min-height="18rem" fluid>

    </v-container> -->
    <!-- <v-container class="px-12 pt-14" fluid> -->

    <img class="tw-object-bottom tw-w-full tw-max-h-72 tw-object-cover" src="../assets/svg/header_login.svg" alt="" />
    <img class="tw-absolute tw-left-8 tw-top-8 tw-w-40" src="/images/logo.png" alt="" />
    <v-container class="flex-column justify-start px-8" fluid>
        <v-form ref="form" class="tw-flex tw-flex-col tw-gap-5" @submit.prevent="finish">
            <h1 class="tw-text-2xl text-primary tw-font-bold">Recuperar Password</h1>
            <v-text-field hide-details="auto" v-model="password" :rules="[rules.required, (v) => rules.min(v, 6)]" placeholder="Insira a Password" type="password" variant="outlined"></v-text-field>
            <v-text-field
                hide-details="auto"
                v-model="passwordConfirmation"
                :rules="[rules.required, (v) => rules.comparePassword(v, password)]"
                placeholder="Confirme a password"
                type="password"
                variant="outlined">
            </v-text-field>
            <v-btn :loading="loading" width="110" type="submit" flat color="primary">Concluir</v-btn>

            <p v-if="errorMessage" class="tw-text-red">{{ errorMessage }}</p>
        </v-form>
    </v-container>
</template>

<script lang="ts" setup>
    import { ref } from 'vue';
    import { useRouter, useRoute } from 'vue-router';
    import axios from '@/plugins/axios';
    import rules from '@/composables/rules';

    const $router = useRouter();
    const $route = useRoute();

    const password = ref('');
    const passwordConfirmation = ref('');
    const errorMessage = ref('');
    const form = ref<HTMLFormElement>();
    const loading = ref(false);

    const finish = async () => {
        if (!form.value) return;

        // reset validation
        form.value?.resetValidation();
        errorMessage.value = '';

        // eslint-disable-next-line no-unsafe-optional-chaining
        const { valid, errors } = await form.value?.validate();

        if (!valid) return;
        loading.value = true;

        try {
            const res = await axios.post('/auth/reset-password', {
                code: $route.query.code,
                password: password.value,
                passwordConfirmation: passwordConfirmation.value,
            });

            if (res.data.ok) {
                $router.push({ name: 'Login' });
            }
        } catch (e: any) {
            const error = e.response.data.error.message;
            console.log(error);
            errorMessage.value = 'Ocorreu um erro ao alterar a password';
            loading.value = false;
        }
    };
</script>
