<template>
    <Top :back="true" :create="false" :reverse="false" :notifications="false" />
    <div class="tw-relative tw-mb-14 -tw-translate-y-0.5">
        <img class="tw-object-bottom tw-w-full tw-max-h-24 tw-object-cover" src="@/assets/svg/header_profile.svg" alt="" />
        <img
            class="tw-rounded-full tw-aspect-square tw-absolute tw-w-36 -tw-bottom-1 tw-left-1/2 tw-translate-y-[40%] -tw-translate-x-1/2 tw-outline tw-outline-[0.3rem] tw-outline-white tw-bg-gray_lightest tw-object-cover"
            :src="useMedia(infrastructure.picture, 'infrastructure').original"
            alt="" />
    </div>
    <v-loading></v-loading>
    <v-container class="">
        <h1 class="tw-text-2xl text-black tw-font-bold tw-text-center">{{ infrastructure.name }}</h1>
        <!-- text area for address -->
        <p class="tw-text-center tw-mt-2">{{ infrastructure.address }}</p>
        <v-row align="center" justify="center" class="fill-width h-auto justify-start align-content-start" no-gutters>
            <template v-if="infrastructure.water_networks && infrastructure.water_networks.length > 0">
                <v-col cols="12" v-for="(network, i) in infrastructure.water_networks" :key="i">
                    <Card
                        :image="waterNetworkIcon(network.type)"
                        class="tw-mt-4"
                        :title="network.name"
                        :to="{ name: 'Network', params: { infrastucture_id: $route.params.infrastucture_id, water_network_id: network.id } }" />
                </v-col>
            </template>
            <template v-else>
                <!-- no water networks where found -->
                <v-col cols="12">
                    <p class="tw-text-center tw-mt-8">Não foram encontradas redes de água.</p>
                </v-col>
            </template>
        </v-row>
    </v-container>
</template>

<script setup lang="ts">
    import Top from '../Layout/Top.vue';
    import Card from '../Layout/Card.vue';
    import { ref } from 'vue';
    import axios from '@/plugins/axios';
    import { useRoute } from 'vue-router';
    import useMedia, { waterNetworkIcon } from '@/composables/useMedia';

    let infrastructure = ref({});

    const $route = useRoute();
    const url = import.meta.env.VITE_API_URL;

    const getInfrastructure = async () => {
        const res = await axios.get(`/infrastructures/${$route.params.infrastucture_id}?populate=*`);

        infrastructure.value = res.data.data;
    };

    getInfrastructure();
</script>

<style scoped></style>
