<template>
    <Top :notifications="false" back :reverse="false" />
    <div class="tw-relative tw-mb-14 -tw-translate-y-0.5">
        <img class="tw-object-bottom tw-w-full tw-max-h-24 tw-object-cover" src="@/assets/svg/header_profile.svg" alt="" />
        <img
            class="tw-rounded-full tw-aspect-square tw-absolute tw-w-36 -tw-bottom-1 tw-left-1/2 tw-translate-y-[40%] -tw-translate-x-1/2 tw-outline tw-outline-[0.3rem] tw-outline-white tw-bg-gray_lightest tw-object-cover"
            :src="waterNetworkIcon(network.type)"
            alt="" />
    </div>
    <v-container class="">
        <h1 class="tw-text-2xl text-black tw-font-bold tw-text-center">{{ network.name }}</h1>
        <!-- text area for address -->
        <p class="tw-text-center tw-mt-2">{{ network.type }}</p>
        <v-row align="center" justify="center" class="fill-width h-auto justify-start align-content-start mt-4" no-gutters>
            <template v-if="network.control_points && network.control_points.length > 0">
                <v-col cols="12" v-for="(controlPoint, i) in network.control_points" :key="i">
                    <Card :image="useMedia(controlPoint.picture, 'control-point').small" :arrow="false" class="tw-mt-4" :title="pointT(controlPoint)" />
                </v-col>
            </template>
            <template v-else>
                <!-- no control points where found -->
                <v-col cols="12">
                    <p class="tw-text-center tw-mt-8">Não foram encontrados pontos de controlo.</p>
                </v-col>
            </template>
        </v-row>
    </v-container>
</template>

<script setup lang="ts">
    import Top from '../Layout/Top.vue';
    import { useRoute } from 'vue-router';
    import axios from '@/plugins/axios';
    import { ref, computed, onMounted } from 'vue';
    import useMedia, { waterNetworkIcon } from '@/composables/useMedia';
    import Card from '../Layout/Card.vue';
    import { ControlPoint } from '../../types';

    const $route = useRoute();

    const network = ref({});

    const getNetwork = async () => {
        const res = await axios.get(`/water-networks/${$route.params.water_network_id}?populate=control_points`);
        network.value = res.data.data;
    };

    onMounted(() => {
        getNetwork();
    });

    const pointT = (controlPoint: ControlPoint) => {
        return controlPoint.name + ' - ' + controlPoint.type;
    };
</script>

<style scoped></style>
