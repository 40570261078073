/**
 * plugins/vuetify.ts
 *
 * Framework documentation: https://vuetifyjs.com`
 */

// Styles
import '@mdi/font/css/materialdesignicons.css';
import 'vuetify/styles';

// Composables
import { createVuetify } from 'vuetify';
import theme from '../../theme';

// https://vuetifyjs.com/en/introduction/why-vuetify/#feature-guides
export default createVuetify({
    locale: {
        messages: {
            pt: {
                noDataText: 'Sem dados disponíveis',
                open: 'Abrir',
                close: 'Fechar',
                input: {
                    clear: 'Limpar',
                }
            },
        },
        locale: 'pt',
    },
    defaults: {
        VField: {
            bgColor: 'gray_lightest',
        },
    },
    display: {
        mobileBreakpoint: 'sm',
        thresholds: {
            xs: 0,
            sm: 640,
            md: 768,
            lg: 1024,
            xl: 1280,
            xxl: 1536,
        },
    },
    theme: {
        themes: {
            light: {
                colors: theme.colors,
            },
        },
    },
});
