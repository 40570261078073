<template>
    <Top :back="true" :create="false" :reverse="false" :notifications="false" :edit="(textInspectionState == 'Por aprovar' || textInspectionState == 'Confirmado') && !isEditing" />
    <div class="tw-relative tw-mb-14 -tw-translate-y-0.5">
        <img class="tw-object-bottom tw-w-full tw-max-h-24 tw-object-cover" src="@/assets/svg/header_profile.svg" alt="" />
        <div
            class="tw-bg-primary tw-flex tw-items-center tw-justify-center tw-rounded-full tw-aspect-square tw-absolute tw-w-36 -tw-bottom-1 tw-left-1/2 tw-translate-y-[40%] -tw-translate-x-1/2 tw-outline tw-outline-[0.3rem] tw-outline-white tw-overflow-hidden">
            <img v-if="!infrastructurePicture" class="tw-w-1/2 tw-h-1/2 tw-object-contain" src="@/assets/icons/calendar_inspections.svg" alt="" />
            <img v-else class="tw-w-full tw-h-full tw-object-cover" :src="infrastructurePicture" alt="" />
        </div>
    </div>
    <v-container class="d-flex flex-column align-center tw-gap-4">
        <h1 class="tw-text-2xl text-black tw-font-bold tw-text-center">{{ inspection.infrastructure.name }}</h1>
        <p :class="colorInspectionSate" class="tw-rounded-md tw-p-1 tw-w-fit tw-text-regular tw-font-medium tw-text-white">{{ textInspectionState }}</p>
    </v-container>
    <v-container class="flex-column justify-start !tw-pt-2" fluid>
        <v-row align="center" class="fill-width h-auto justify-center align-content-center">
            <v-col cols="12">
                <VueDatePicker :readonly="!isEditing" v-model="inspection.date" placeholder="Data e hora da inspeção" />
            </v-col>
            <v-col cols="12">
                <v-textarea
                    :readonly="!isEditing"
                    v-model="inspection.observations"
                    hide-details="auto"
                    rows="10"
                    label="Observações"
                    placeholder="Observações"
                    variant="outlined"></v-textarea>
            </v-col>
            <v-col v-if="isEditing" cols="6">
                <v-btn @click="update" block size="large" flat color="primary">Guardar</v-btn>
            </v-col>
            <v-col v-if="textInspectionState == 'Por aprovar' || textInspectionState == 'Confirmado'" cols="6">
                <v-btn @click="cancel" block size="large" flat color="red">Cancelar inspeção</v-btn>
            </v-col>
            <v-col v-if="inspection.files !== null" cols="6">
                <a target="_blank" :href="downloadFileUrl" download>
                    <v-btn block size="large" flat color="primary">Download</v-btn>
                </a>
            </v-col>
        </v-row>
    </v-container>
</template>

<script setup lang="ts">
    import Top from '../Layout/Top.vue';
    import { ref, onMounted, computed } from 'vue';
    import axios from '@/plugins/axios';
    import { useRoute, useRouter } from 'vue-router';
    import VueDatePicker from '@vuepic/vue-datepicker';
    import useMedia from '@/composables/useMedia';
    import _ from 'lodash';
    import { useAppStore } from '../../store/app';

    const $route = useRoute();
    const $router = useRouter();
    const appStore = useAppStore();

    const inspection = ref({ infrastructure: { name: '' }, status: '', date: '', observations: '', files: null });

    const getInspection = async () => {
        const res = await axios.get(`/inspections/${$route.params.idInspection}?populate=infrastructure.picture,files`);
        inspection.value = res.data.data;
    };

    const isEditing = computed(() => appStore.isEditing);

    const colorInspectionSate = computed(() => {
        switch (inspection.value.status) {
            case 'concluído':
            case 'confirmado':
                return 'tw-bg-green';
            case 'negado':
            case 'cancelado':
                return 'tw-bg-red';
            default:
                return 'tw-bg-yellow';
        }
    });

    const infrastructurePicture = computed(() => {
        return useMedia(_.get(inspection.value, 'infrastructure.picture'), 'infrastructure').small;
    });

    const textInspectionState = computed(() => {
        switch (inspection.value.status) {
            case 'concluído':
                return 'Concluída';
            case 'confirmado':
                return 'Confirmado';
            case 'negado':
                return 'Negado';
            case 'cancelado':
                return 'Cancelada';
            default:
                return 'Por aprovar';
        }
    });

    const cancel = async () => {
        const res = await axios.put(`/inspections/${inspection.value.id}`, { data: { status: 'cancelado' } });
        $router.push({ name: 'Inspections' });
    };

    const downloadFileUrl = computed(() => {
        return import.meta.env.VITE_API_URL + '' + inspection.value.files[0].url;
    });

    const update = async () => {
        const res = await axios.put(`/inspections/${$route.params.idInspection}`, { data: inspection.value });
        console.log(res.data.data);
        $router.push({ name: 'Inspections' });
    };

    onMounted(() => {
        getInspection();
    });
</script>

<style scoped></style>
