<template>
    <TopBar back :notifications="false" :reverse="false" :date="dateTransform" :absolute="false" />
    <div class="tw-relative tw-text-white tw-h-64 tw-p-8 tw-px-24 -tw-translate-y-1 tw-flex tw-flex-col tw-gap-2 tw-bg-[url('../../assets/svg/header_profile.svg')]">
        <!-- <img class="tw-object-bottom tw-w-full tw-max-h-72 tw-object-cover" src="../../assets/svg/header_profile.svg" alt="" /> -->
        <p v-if="control_point.water_network.infrastructure.name" class="tw-text-center tw-font-bold tw-text-base">{{ control_point.water_network.infrastructure.name }}</p>
        <p v-if="control_point.name" class="tw-text-center tw-font-light tw-text-base">{{ control_point.name }}</p>
        <p :class="{ 'tw-bg-green': state, 'tw-bg-yellow': !state }" class="tw-text-center tw-text-sm tw-p-1 tw-rounded-lg">
            {{ state ? 'Análise concluída com sucesso' : 'Problema na análise' }}
        </p>
    </div>
    <Cold_Hot v-if="control_point.water_network.type == 'quente' || control_point.water_network.type == 'fria'" :readings="readings" />
    <Pool v-else-if="control_point.water_network.type == 'piscina'" :readings="readings" />
</template>

<script setup lang="ts">
    import TopBar from '@/views/Layout/TopBar.vue';
    import Cold_Hot from './Reading/Cold_Hot.vue';
    import Pool from './Reading/Pool.vue';
    import { ref, onMounted, computed } from 'vue';
    import { useRoute } from 'vue-router';
    import axios from '@/plugins/axios';
    import qs from 'qs';

    const $route = useRoute();
    const idReadingSet = ref();
    const data = ref({ date: new Date().toISOString().substr(0, 10) });
    const readings = ref([]);
    const control_point = ref({ water_network: { infrastructure: {} } });
    const state = ref();

    const query = ref({
        populate: ['readings', 'control_point.water_network.infrastructure'],
    });

    const getReading = async () => {
        const res = await axios.get(`/reading-sets/${idReadingSet.value}?${qs.stringify(query.value)}`);
        data.value = res.data.data;

        readings.value = data.value.readings;
        control_point.value = data.value.control_point;
    };

    const dateTransform = computed(() => {
        if (!data.value.date) {
            return new Date().toISOString().substr(0, 10).split('-').reverse().join('/');
        } else {
            return data.value.date.split('T')[0].split('-').reverse().join('/');
        }
    });

    onMounted(async () => {
        idReadingSet.value = $route.params.id;
        await getReading();

        state.value = readings.value.every((reading) => reading.status == 'conforme' || reading.status == '' || reading.status == null || reading.status == 'risco baixo');
    });
</script>

<style scoped></style>
