import { Media } from '@/types';
import _ from 'lodash';

type MediaType = 'infrastructure' | 'user' | 'control-point' | 'water-network';

function defaultByType(type: MediaType | null) {
    switch (type) {
        case 'infrastructure':
            return '/images/svg/infrastructure.svg';
        case 'user':
            return '/images/svg/user.svg';
        case 'control-point':
            return '/images/svg/control-point.svg';
        case 'water-network':
            return '/images/svg/water-network.svg';
        default:
            return '/images/svg/image.svg';
    }
}

export default function useMedia(media: Media | undefined, type: MediaType | null = null) {
    if (!media)
        return {
            thumbnail: defaultByType(type),
            small: defaultByType(type),
            medium: defaultByType(type),
            large: defaultByType(type),
            original: defaultByType(type),
        };

    function joinUrl(url: string) {
        if (url.startsWith('http')) {
            return url;
        }
        return import.meta.env.VITE_API_URL + url;
    }
    return {
        thumbnail: joinUrl(_.get(media, 'formats.thumbnail.url', _.get(media, 'url', ''))),
        small: joinUrl(_.get(media, 'formats.small.url', _.get(media, 'url', ''))),
        medium: joinUrl(_.get(media, 'formats.medium.url', _.get(media, 'url', ''))),
        large: joinUrl(_.get(media, 'formats.large.url', _.get(media, 'url', ''))),
        original: joinUrl(_.get(media, 'url', '')),
    };
}

export function waterNetworkIcon(type: 'quente' | 'fria' | 'piscina' = 'piscina') {
    switch (type) {
        case 'quente':
            return '/images/svg/hot_colors.svg';
        case 'fria':
            return '/images/svg/cold_colors.svg';
        default:
            return '/images/svg/pool_colors.svg';
    }
}