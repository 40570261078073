<template>
    <v-form ref="form" class="tw-flex tw-flex-col tw-gap-3" @submit.prevent="submit">
        <v-text-field
            v-if="true"
            v-model="json[1].value"
            type="number"
            hide-details="auto"
            :rules="[rules.required, (v) => rules.minNumber(v), (v) => rules.maxNumber(v, 15)]"
            label="pH"
            hint="entre 6,5 e 9,5"
            variant="outlined"></v-text-field>
        <v-text-field
            v-if="true"
            v-model="json[2].value"
            type="number"
            hide-details="auto"
            :rules="[rules.required, (v) => rules.minNumber(v), (v) => rules.maxNumber(v)]"
            label="Desinfetante"
            hint="entre 0,5 e 1"
            variant="outlined"></v-text-field>
        <v-text-field
            v-if="true"
            v-model="json[0].value"
            type="number"
            hide-details="auto"
            :rules="[rules.required, (v) => rules.minNumber(v, -100), (v) => rules.maxNumber(v)]"
            label="Temperatura"
            hint="> 50ºC"
            variant="outlined"></v-text-field>
            <!-- @vue-ignore -->
        <v-text-field
            v-if="(json[2].value > 1 || (json[2].value < 0.5 && json[2].value != null && json[2].value != '')) && true && readingtype == 'microbiológica-legionella'"
            v-model="json[3].value"
            type="number"
            hide-details="auto"
            :rules="[rules.required, (v) => rules.minNumber(v, -100), (v) => rules.maxNumber(v)]"
            label="Colonias"
            hint="> 1"
            variant="outlined"></v-text-field>
        <v-text-field
            class="italic"
            v-if="readingtype == 'microbiológica-legionella'"
            v-model="json[4].value"
            type="number"
            hide-details="auto"
            :rules="[rules.required, (v) => rules.minNumber(v, 0), (v) => rules.maxNumber(v, 100000)]"
            label="Legionella Pneumophila"
            hint="= 0"
            variant="outlined"></v-text-field>
        <v-text-field
            v-if="readingtype == 'microbiológica-legionella'"
            v-model="json[5].value"
            type="number"
            hide-details="auto"
            :rules="[rules.required, (v) => rules.minNumber(v, 0), (v) => rules.maxNumber(v, 100000)]"
            label="Legionella spp"
            hint="< 100"
            variant="outlined"></v-text-field>
        <p v-if="errorMessage" class="tw-text-red" v-html="errorMessage"></p>
        <v-btn variant="tonal" :loading="loading" type="submit" block rounded="lg" class="text-none bg-primary tw-mt-6"> Concluir</v-btn>
    </v-form>
</template>

<script setup lang="ts">
    import { ref } from 'vue';
    import rules from '@/composables/rules';
    import axios from '@/plugins/axios';
    import { useRouter } from 'vue-router';
    import _ from 'lodash';

    const props = defineProps({
        idReadingSet: {
            required: false,
        },
        readingtype: {
            required: false,
        },
    });

    const $router = useRouter();
    const loading = ref(false);
    const form = ref<HTMLFormElement>();
    const errorMessage = ref('');

    const json = ref([
        {
            field: 'temperatura',
            value: null,
        },
        {
            field: 'ph',
            value: null,
        },
        {
            field: 'desinfetante',
            value: null,
        },
        {
            field: 'colonias',
            value: null,
        },
        {
            field: 'legionella_pneumophila',
            value: null,
        },
        {
            field: 'legionella_spp',
            value: null,
        },
    ]);

    const submit = async () => {
        if (!form.value) return;

        // reset validation
        form.value?.resetValidation();
        errorMessage.value = '';

        // eslint-disable-next-line no-unsafe-optional-chaining
        const { valid } = await form.value?.validate();

        if (!valid) return;

        loading.value = true;

        let readings = json.value.filter((reading: any) => reading.value !== null);

        try {
            const res = await axios.post('/readings/many', { data: readings });
            const data = res.data.data;

            let readingIds = data.map((reading: any) => reading.id);

            await axios.put(`/reading-sets/${props.idReadingSet}`, { data: { readings: readingIds } });
            $router.push('/readings');
        } catch (error: any) {
            let message = _.get(error, 'response.data.error.message', _.get(error, 'message', ''));
            errorMessage.value = `Ocorreu um erro: <br> ${message}`;
        } finally {
            loading.value = false;
        }
    };
</script>

<style scoped></style>
