import axios from 'axios';
import { useUserStore } from '@/store/user';

const instance = axios.create({
    baseURL: import.meta.env.VITE_API_URL+'/api',
    headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
    },
});

// add token to request header
instance.interceptors.request.use((config) => {
    const token = useUserStore().token;

    if (token) {
        config.headers.Authorization = `Bearer ${token}`;
    }

    return config;
});

export default instance;
