<template>
    <v-app>
        <v-main>
            <router-view></router-view>
            <v-alert
                v-if="errorMessage"
                closable
                class="!tw-bottom-4"
                color="red"
                density="compact"
                width="80%"
                max-width="22rem"
                location="bottom center"
                position="fixed"
                :text="errorMessage"></v-alert>
        </v-main>
        <BottomNav v-if="showBottomBar" />
        <PromptUpdate />
    </v-app>
</template>

<script setup lang="ts">
    import { computed, onMounted } from 'vue';
    import { useRoute, useRouter } from 'vue-router';
    import BottomNav from './views/Layout/BottomNav.vue';
    import PromptUpdate from './views/PromptUpdate.vue';
    import axios from '@/plugins/axios';
    import { useUserStore } from '@/store/user';
    import qs from 'qs';
    import _ from 'lodash';
    import { ref } from 'vue';

    const $route = useRoute();
    const $router = useRouter();
    const userStore = useUserStore();
    const errorMessage = ref('');

    const showBottomBar = computed(() => {
        // check if $route has the meta "hideBottomBar"
        return !$route.meta.hideBottomNav;
    });

    async function validateToken() {
        try {
            if (!$route.meta.auth) return;
            if (!userStore.getToken) {
                userStore.$reset();
                $router.push({ name: 'Login' });
                return;
            }
            const res = await axios.get(`/users/me?${qs.stringify({ populate: ['role', 'picture', 'client'] })}`);
            useUserStore().setUser(res.data);
        } catch (error) {
            let message = _.get(error, 'response.data.error.message', _.get(error, 'message', ''));
            switch (message) {
                case 'Missing or invalid credentials':
                    message = 'Credenciais inválidas, por favor faça login novamente';
                    break;

                default:
                    message = `Ocorreu um erro:<br>${message}`;
                    break;
            }
            errorMessage.value = message;
            userStore.$reset();
            $router.push({ name: 'Login' });
        }
    }
    validateToken();
</script>
