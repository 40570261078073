// Utilities
import { defineStore } from 'pinia';

export const useAppStore = defineStore('app', {
    state: () => ({
        editing: false,
    }),
    getters: {
        isEditing: (state) => state.editing,
    },
    actions: {
        setEditing(value: boolean) {
            this.editing = value;
        },
    },
});
