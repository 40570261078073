<template>
    <div ref="bottomBar" class="flex tw-h-[5.75rem] tw-w-full tw-sticky tw-bottom-0 tw-mt-8">
        <img class="tw-w-full tw-object-top tw-object-cover tw-h-full tw-absolute tw-z-0 tw-bottom-0" src="../../assets/svg/bottom_navigation.svg" alt="" />
        <div class="tw-flex tw-justify-around tw-w-full tw-h-full tw-items-end tw-z-10 tw-pb-[1.5rem]">
            <router-link :to="{ name: 'Readings' }">
                <img class="tw-z-10 tw--rotate-45 tw-w-[1.5rem] icon-bottom-nav" src="/icons/push-pin.svg" alt="" />
            </router-link>
            <router-link :to="{ name: 'Analysis' }">
                <img class="tw-z-10 tw-w-[1.5rem] icon-bottom-nav" src="/icons/profit.svg" alt="" />
            </router-link>
            <img
                @click="goToInfrastructures"
                @load="imgLoaded"
                ref="btnCenterNav"
                class="tw-rounded-full tw-z-10 tw-w-[4.25rem] tw-border-[0.4rem] tw-border-solid tw-border-primary tw-translate-y-[-40%]"
                src="/logo.svg"
                alt="" />
            <router-link :to="{ name: 'Inspections'}">
                <img class="tw-z-10 tw-w-[1.5rem] icon-bottom-nav" src="/icons/calendar.svg" alt="" />
            </router-link>
            <router-link :to="{ name: 'Profile' }">
                <img class="tw-z-10 tw-w-[1.5rem] icon-bottom-nav" src="/icons/user.svg" alt="" />
            </router-link>
        </div>
        <!-- <img class="tw-z-10 tw--rotate-45" src="../../assets/icons/push-pin.svg" alt=""> -->
    </div>
</template>

<script setup lang="ts">
    import { ref } from 'vue';
    import { onMounted } from 'vue';
    import { useRouter } from 'vue-router';

    const $router = useRouter();

    const hasLoaded = ref(false);
    const hasMounted = ref(false);

    const bottomBar = ref<HTMLElement | null>(null);
    const btnCenterNav = ref<HTMLElement | null>(null);

    onMounted(() => {
        hasMounted.value = true;
        setNavHeight();
    });

    function imgLoaded() {
        hasLoaded.value = true;
        setNavHeight();
    }

    function setNavHeight() {
        if (!hasLoaded.value || !hasMounted.value) return;
        let navHeight = bottomBar.value?.offsetHeight ?? 0;
        let btnHeight = btnCenterNav.value?.offsetHeight ?? 0;
        navHeight += btnHeight;

        document.documentElement.style.setProperty('--bottomBarHeight', `${navHeight}px`);
    }

    const goToInfrastructures = () => {
        $router.push({ name: 'Infrastructures' });
    };
</script>

<style scoped></style>
