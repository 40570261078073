<template>
    <Top :back="true" :edit="false" :notifications="false" />
    <div class="tw-relative -tw-translate-y-0.5">
        <img class="tw-object-bottom tw-w-full tw-max-h-18 tw-object-cover" src="../assets/svg/header_profile.svg" alt="" />
        <h1 v-if="isAdding" class="tw-text-xl tw-text-white tw-mb-4 -tw-translate-y-12 tw-text-center">Criar técnico</h1>
        <h1 v-else class="tw-text-xl tw-text-white tw-mb-4 -tw-translate-y-12 tw-text-center">Editar técnico</h1>
    </div>
    <v-container class="flex-column justify-start tw-relative tw-flex tw-flex-1 !tw-pt-0" fluid>
        <v-form ref="form" @submit.prevent="createTechnician">
            <v-row align="center" justify="center" class="fill-width h-auto justify-start align-content-start" no-gutters>
                <v-col cols="10" class="">
                    <v-text-field hide-details="auto" v-model="name" :rules="[rules.required]" required placeholder="Nome do técnico" type="email" variant="outlined"></v-text-field>
                </v-col>
                <v-col cols="10" class="mt-4">
                    <v-text-field hide-details="auto" v-model="email" :rules="[rules.required, rules.email]" required placeholder="Email" type="email" variant="outlined"></v-text-field>
                </v-col>
                <v-col cols="10" class="tw-mt-10 tw-flex">
                    <h2 class="tw-w-fit tw-text-large tw-font-bold">Adicionar infraestrutura</h2>
                    <div class="tw-w-fit tw-rounded-full tw-bg-gray tw-ml-1 tw-p-1" v-if="(selectedInfrastructures[0] !== 'all') && (selectedInfrastructures.length <= infrastructuresSelect.length)">
                        <a @click="selectedInfrastructures.push(null)"> <img src="/icons/plus.svg" alt="" /></a>
                    </div>
                </v-col>
                <v-col v-for="(selected, index) in selectedInfrastructures" :key="index" cols="10" class="mt-4">
                    <v-select
                        :rules="[]"
                        hide-details="auto"
                        clearable
                        variant="outlined"
                        item-value="id"
                        item-title="name"
                        v-model="selectedInfrastructures[index]"
                        :items="infrastructuresSelect(index)">
                        <template #append>
                            <div class="tw-w-fit tw-rounded-full tw-bg-gray tw-ml-1 tw-p-1" v-if="index > 0" @click="selectedInfrastructures.splice(index, 1)" icon>
                                <v-icon class="tw-text-primary tw-font-semibold">mdi-minus</v-icon>
                            </div>
                        </template>
                    
                    </v-select>
                </v-col>
            </v-row>
            <v-row align="end" justify="center" class="fill-width fill-height justify-center align-content-end mt-4" no-gutters>
                <v-col cols="10" v-if="errorMessage">
                    <p class="tw-text-red tw-text-center tw-mb-4" v-html="errorMessage"></p>
                </v-col>
                <v-col cols="6">
                    <v-btn color="primary" :loading="loading" type="submit" block rounded="lg" size="large">Guardar</v-btn>
                </v-col>
            </v-row>
        </v-form>
    </v-container>
</template>

<script setup lang="ts">
    import Top from './Layout/Top.vue';
    import { watch, ref, onMounted, computed } from 'vue';
    import rules from '@/composables/rules';
    import axios from '@/plugins/axios';
    import { useRoute, useRouter } from 'vue-router';
    import { useUserStore } from '@/store/user';
    import _ from 'lodash';
    import qs from 'qs';

    const email = ref('');
    const name = ref('');
    const infrastructures = ref([]);
    const selectedInfrastructures = ref(['all']);
    const form = ref<HTMLFormElement>();
    const loading = ref(false);
    const $router = useRouter();
    const $route = useRoute();
    const userStore = useUserStore();
    const errorMessage = ref('');

    const getInfrastructures = async () => {
        const res = await axios.get(`/infrastructures?populate=client&sort=name&filters[client][users][id][$eq]=${userStore.getUser.id}`);
        infrastructures.value = res.data.data;
    };

    const me = computed(() => userStore.getUser);

    const isAdding = computed(() => {
        return $route.name == 'CreateTechnician';
    });

    function infrastructuresSelect(index: number) {
        const infra = [];
        if (index == 0) {
            infra.push({
                id: 'all',
                name: 'Todas as infraestruturas',
            });
        }
        return [...infra, ...infrastructures.value];
    }

    const createTechnician = async () => {
        if (!form.value) return;
        errorMessage.value = '';
        // reset validation
        form.value?.resetValidation();

        // eslint-disable-next-line no-unsafe-optional-chaining
        const { valid, errors } = await form.value?.validate();
        if (!valid) return;

        loading.value = true;

        function generateRandomString(length = 16) {
            const characters = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
            return [...Array(length)].map(() => characters[Math.floor(Math.random() * characters.length)]).join('');
        }

        const randomString = generateRandomString();

        const data = {
            data: {
                name: name.value,
                email: email.value,
                username: email.value,
                ...(isAdding.value && { password: randomString }),
                role: 1,
                client: me.value.client.id,
                infrastructures: _.get(selectedInfrastructures.value, '0') == 'all' ? infrastructures.value.map((infra) => infra.id) : selectedInfrastructures.value.filter((infra) => infra !== null),
            },
        };

        try {
            if (isAdding.value) {
                const res = await axios.post('/users', data);
            } else {
                const res = await axios.put(`/users/${$route.params.id}`, data);
            }
            $router.push({ name: 'Profile' });
        } catch (e: any) {
            let message = _.get(e, 'response.data.error.message', '');
            switch (message) {
                case 'Email already taken':
                    message = 'Este email já se encontra em uso.';
                    break;
                default:
                    message = 'Ocorreu um erro: <br>' + e.message;
                    break;
            }
            errorMessage.value = message;
        } finally {
            loading.value = false;
        }
    };

    async function fetchTechnician() {
        const query = {
            populate: ['infrastructures'],
        };
        const res = await axios.get(`/users/${$route.params.id}?${qs.stringify(query)}`);
        const technician = res.data;
        name.value = technician.name;
        email.value = technician.email;
        selectedInfrastructures.value = technician.infrastructures.map((infra) => infra.id);
    }

    // watch the infrastructuresSelect to remove all if the first item is all
    watch(selectedInfrastructures.value, (newValue) => {
        console.log(newValue);
        if (newValue[0] == 'all') {
            for (let i = 1; i < selectedInfrastructures.value.length; i++) {
                selectedInfrastructures.value.pop();
            }
            
        }
    });

    onMounted(async () => {
        await getInfrastructures();
        if (!isAdding.value) {
            await fetchTechnician();
        }
    });
</script>

<style lang="scss" scoped></style>
