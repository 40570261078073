<template>
    <Top title="Infraestruturas" :notifications="!userStore.isTechnician" />
    <v-container class="flex-column justify-start -tw-translate-y-0.5" fluid>
        <v-row align="center" justify="center" class="fill-width h-auto justify-start align-content-start" no-gutters>
            <v-col cols="11">
                <InputText v-model="search" class="tw-mb-4" placeholder="Pesquisar" />
                <Card
                    v-for="(infra, i) in infrastructures"
                    class="tw-mt-4"
                    :title="infra.name"
                    :image="useMedia(infra.picture, 'infrastructure').thumbnail"
                    :to="{ name: 'Infrastructure', params: { infrastucture_id: infra.id } }"
                    :key="i" />
            </v-col>
        </v-row>
    </v-container>
</template>

<script setup lang="ts">
    import Top from '../Layout/Top.vue';
    import InputText from '../Layout/Forms/InputText.vue';
    import Card from '../Layout/Card.vue';
    import axios from '@/plugins/axios';
    import useMedia from '@/composables/useMedia';
    import { ref, watch, computed, onMounted } from 'vue';
    import qs from 'qs';
    import { useUserStore } from '@/store/user';

    const infrastructures = ref([]);
    const search = ref('');
    const userStore = useUserStore();

    const getInfrastructures = async () => {
        const query = {
            sort: ['name'],
            filters: {
                ...(search.value && {
                    name: {
                        $containsi: search.value,
                    },
                }),
                ...(userStore.isTechnician
                    ? {
                          users: {
                              id: {
                                  $eq: userStore.getUser.id,
                              },
                          },
                      }
                    : {
                          client: {
                              id: {
                                  $eq: userStore.getUser.client?.id,
                              },
                          },
                      }),
            },
            populate: 'picture',
        };

        const res = await axios.get(`/infrastructures?${qs.stringify(query)}`);
        infrastructures.value = res.data.data;
    };

    watch(search, () => {
        getInfrastructures();
    });

    onMounted(() => {
        getInfrastructures();
    });
</script>

<style scoped></style>
