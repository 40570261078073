<template>
    <v-card @click="$router.push({ name: 'Inspection', params: { idInspection: inspection.id } })" color="primary_lightest" class="tw-w-full tw-overflow-initial elevation-0 rounded-lg">
        <div class="!tw-p-2 !tw-flex tw-flex-row tw-items-center tw-justify-between tw-gap-2">
            <v-avatar :class="infrastructurePicture.class" color="white" size="52" rounded="lg">
                <v-img :aspect-ratio="1" cover :src="infrastructurePicture.src"></v-img>
            </v-avatar>
            <div class="tw-grow tw-flex tw-text-xs tw-justify-between tw-self-stretch tw-my-1">
                <div class="tw-flex tw-flex-col tw-justify-between tw-gap-1 tw-overflow-hidden">
                    <p class="tw-font-semibold !tw-leading-none tw-line-clamp-2">{{ infrastructureName }}</p>
                    <span>{{ inspectionDate }}</span>
                </div>
                <div>
                    <v-chip :elevation="0" size="x-small" variant="elevated" :color="colorInspectionStatus">{{ textInspectionStatus }}</v-chip>
                </div>
            </div>
            <v-icon class="text-h4 before:tw-text-primary" aria-hidden="false"> mdi-chevron-right </v-icon>
        </div>
    </v-card>
</template>

<script setup lang="ts">
    import imgCalendar from '@/assets/icons/calendar_inspections.svg';

    import moment from 'moment';

    import type { Inspection } from '@/types';
    import { computed } from 'vue';
    import useMedia from '../../composables/useMedia';

    const props = defineProps<{
        inspection: Inspection;
    }>();

    const infrastructurePicture = computed(() => {
        const picture = useMedia(props.inspection.infrastructure?.picture, 'infrastructure').small;
        return {
            src: picture || imgCalendar,
            class: picture ? '' : 'tw-p-2',
        };
    });

    const infrastructureName = computed(() => {
        return props.inspection.infrastructure?.name;
    });

    const inspectionDate = computed(() => {
        // return date formatted as DD-MM-YYYY
        return moment(props.inspection.date).format('DD-MM-YYYY');
    });

    const colorInspectionStatus = computed(() => {
        switch (props.inspection.status) {
            case 'concluído':
            case 'confirmado':
                return 'green';
            case 'negado':
            case 'cancelado':
                return 'red';
            default:
                return 'yellow';
        }
    });

    const textInspectionStatus = computed(() => {
        switch (props.inspection.status) {
            case 'concluído':
                return 'Concluído';
            case 'confirmado':
                return 'Confirmado';
            case 'negado':
                return 'Negado';
            case 'cancelado':
                return 'Cancelada';
            default:
                return 'Por aprovar';
        }
    });
</script>

<style scoped></style>
