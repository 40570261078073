<template>
    <Top :back="true" :create="false" :reverse="false" :notifications="false" :absolute="false" />
    <div class="tw-relative -tw-translate-y-0.5">
        <img class="tw-object-bottom tw-w-full tw-max-h-24 tw-object-cover" src="@/assets/svg/header_profile.svg" alt="" />
    </div>
    <h2 class="tw-text-xl text-white tw-font-semibold tw-text-center -tw-translate-y-20">Agendar inspeção</h2>
    <v-form ref="form" @submit.prevent="submit">
        <v-container class="tw-flex tw-flex-col tw-gap-4 justify-start !tw-pt-0" fluid>
            <div>
                <v-select
                    :rules="[rules.required]"
                    hide-details="auto"
                    clearable
                    variant="outlined"
                    placeholder="Todas as infraestrutura"
                    item-value="id"
                    item-title="name"
                    v-model="schedule.infrastructure"
                    :items="infrastructures"></v-select>
            </div>
            <div>
                <VueDatePicker v-model="schedule.date" placeholder="Selecionar data e hora" />
            </div>
            <div>
                <v-textarea v-model="schedule.observations" variant="outlined" hide-details="auto" rows="10" label="Observações" placeholder="Observações"></v-textarea>
            </div>
            <v-btn position="fixed" class="tw-bottom-16 !tw-min-w-[12rem] tw-left-1/2 -tw-translate-x-1/2" type="submit" :loading="loading" size="large" flat color="primary">Agendar</v-btn>
        </v-container>
    </v-form>
</template>

<script setup lang="ts">
    import Top from '../Layout/TopBar.vue';
    import { ref, onMounted, computed } from 'vue';
    import axios from '@/plugins/axios';
    import qs from 'qs';
    import { useRouter } from 'vue-router';
    import rules from '@/composables/rules';
    import VueDatePicker from '@vuepic/vue-datepicker';
    import { Infrastructure } from '../../types';
    import { useUserStore } from '@/store/user';

    const userStore = useUserStore();

    const schedule = ref({
        date: '',
        observations: '',
        infrastructure: null,
    });

    const infrastructures = ref([]);
    const form = ref<HTMLFormElement>();
    const loading = ref(false);
    const $router = useRouter();

    const getInfrastructures = async () => {
        const query = {
            filters: {
                ...(userStore.isTechnician
                    ? {
                          users: {
                              id: {
                                  $eq: userStore.getUser.id,
                              },
                          },
                      }
                    : {
                          client: {
                              id: {
                                  $eq: userStore.getUser.client.id,
                              },
                          },
                      }),
            },
            sort: ['name:asc'],
        };
        const res = await axios.get(`/infrastructures?${qs.stringify(query)}`);
        infrastructures.value = res.data.data;
    };

    const submit = async () => {
        if (!form.value) return;

        // reset validation
        form.value?.resetValidation();

        // eslint-disable-next-line no-unsafe-optional-chaining
        const { valid, errors } = await form.value?.validate();

        if (!valid) return;

        console.log(schedule.value);

        loading.value = true;

        const res = await axios.post(`/inspections`, { data: schedule.value });
        console.log(res.data);
        $router.push({ name: 'Inspections' });
    };

    onMounted(() => {
        getInfrastructures();
    });
</script>

<style scoped></style>
