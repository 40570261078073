<template>
    <!-- <v-container class="bg-primary_DEFAULT px-12 py-8 tw-h-72" min-height="18rem" fluid>

    </v-container> -->
    <!-- <v-container class="px-12 pt-14" fluid> -->

    <img class="tw-object-bottom tw-w-full tw-max-h-72 tw-object-cover" src="../assets/svg/header_login.svg" alt="" />
    <img class="tw-absolute tw-left-8 tw-top-8 tw-w-40" src="/images/logo.png" alt="" />
    <v-container class="flex-column justify-start px-8" fluid>
        <v-form ref="form" class="tw-flex tw-flex-col tw-gap-5" @submit.prevent="finish">
            <h1 class="tw-text-2xl text-primary tw-font-bold">Recuperar Password</h1>
            <v-text-field hide-details="auto" v-model="email" :rules="[rules.required, rules.email]" placeholder="Insira o seu email" type="email" variant="outlined"></v-text-field>

            <v-btn :loading="loading" width="110" type="submit" flat color="primary">Concluir</v-btn>

            <p v-if="message" class="tw-text-green">{{ message }}</p>
        </v-form>
    </v-container>
</template>

<script lang="ts" setup>
    import { ref, computed } from 'vue';
    import { useRouter } from 'vue-router';
    import axios from '@/plugins/axios';
    import rules from '@/composables/rules';

    const $router = useRouter();

    const email = ref('');
    const form = ref<HTMLFormElement>();
    const loading = ref(false);

    const message = ref('');

    const finish = async () => {
        if (!form.value) return;
        form.value?.resetValidation();
        message.value = '';

        // eslint-disable-next-line no-unsafe-optional-chaining
        const { valid, errors } = await form.value?.validate();
        if (!valid) return;

        loading.value = true;

        const res = await axios.post('/auth/forgot-password', {
            email: email.value,
        });

        $router.push({ name: 'Login' });
    };
</script>
