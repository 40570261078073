<template>
    <v-card @click="goTo" color="primary_lightest" flat>
        <div class="tw-flex tw-flex-row tw-gap-2 tw-p-3">
            <img class="tw-w-16 tw-object-cover tw-rounded-lg" :src="readingImg" alt="" />
            <div class="tw-grow tw-flex tw-flex-col tw-gap-2">
                <p class="tw-text-primary tw-font-semibold tw-text-sm tw-line-clamp-1">{{ infrastructureName }}</p>
                <p class="tw-text-xs tw-line-clamp-1" v-if=isPiscina >{{ date_ }}</p>
                <p class="tw-font-semibold tw-text-xs tw-line-clamp-1">{{ networkName }}</p>
                <p class="tw-text-xs tw-line-clamp-1">{{ pointName }}</p>
                <p class="tw-text-xs tw-text-primary tw-bg-primary_lighter tw-w-fit tw-px-2 tw-font-semibold tw-py-1 tw-capitalize tw-rounded-md tw-leading-none">{{ readingType }}</p>
            </div>
            <v-icon class="tw-self-center tw-translate-x-3" size="48" color="primary" aria-hidden="false"> mdi-chevron-right </v-icon>
        </div>
    </v-card>
</template>

<script setup lang="ts">
    import useMedia from '@/composables/useMedia';
    import { Reading } from '@/types';
    import { computed } from 'vue';
    import { useRouter } from 'vue-router';
    import _ from 'lodash';
    import { useDate } from '@/composables/useDate';

    const $router = useRouter();

    const props = defineProps<{
        reading: Reading;
        create: boolean;
    }>();

    const goTo = () => {
        if (!props.create) $router.push({ name: 'Reading', params: { id: props.reading.id } });
        else $router.push({ name: 'CreateReading', params: { id: props.reading.id } });
    };

    const readingImg = computed(() => {
        return useMedia(_.get(props.reading, 'control_point.water_network.infrastructure.picture'), 'infrastructure').small;
    });

    const infrastructureName = computed(() => {
        return _.get(props.reading, 'control_point.water_network.infrastructure.name');
    });

    const networkName = computed(() => {
        return _.get(props.reading, 'control_point.water_network.name');
    });

    const isPiscina = computed(() => {
        return _.get(props.reading, 'control_point.water_network.type') === 'piscina' && _.get(props.reading, 'reading_type') === "diário";
    });

    const pointName = computed(() => {
        return _.get(props.reading, 'control_point.name');
    });

    const readingType = computed(() => {
        return _.get(props.reading, 'reading_type');
    });

    const date_ = computed(() => {
        return useDate(_.get(props.reading, 'date')).formattedDate;
    });
</script>

<style scoped></style>
