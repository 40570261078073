<template>
    <!-- <v-container class="bg-primary_DEFAULT px-12 py-8 tw-h-72" min-height="18rem" fluid>

    </v-container> -->
    <!-- <v-container class="px-12 pt-14" fluid> -->

    <img class="tw-object-bottom tw-w-full tw-max-h-72 tw-object-cover" src="../assets/svg/header_login.svg" alt="" />
    <img class="tw-absolute tw-left-8 tw-top-8 tw-w-40" src="/images/logo.png" alt="" />
    <v-container class="flex-column justify-start px-8" fluid>
        <v-row align="start" class="fill-width h-auto mt-14 justify-start align-content-start" no-gutters>
            <v-col cols="12"><h1 class="tw-text-2xl text-primary tw-font-bold">Complete os seus dados</h1></v-col>
            <v-col cols="12" class="mt-4">
                <InputText label="Password" placeholder="Insira a Password"/>
            </v-col>
            <v-col cols="12" class="mt-4">
                <InputText label="Confirmar a Password" placeholder="Confirme a password"/>
            </v-col>
            <v-col cols="4" class="tw-mt-8">
                <v-btn @click="finish" block variant="tonal" class="text-none bg-primary"> Concluir </v-btn>
            </v-col>
        </v-row>
    </v-container>
</template>

<script lang="ts" setup>
    import InputText from './Layout/Forms/InputText.vue';
    import { useRouter } from 'vue-router';

    const $router = useRouter();

    const finish = () => {
        $router.push({ name: 'Login' });
    };

</script>
