export function required(value: any) {
    return !!value || 'Campo obrigatório!';
}

export function email(value: any) {
    // eslint-disable-next-line
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@(([^<>()\[\]\\.,;:\s@"]+\.)+[^<>()\[\]\\.,;:\s@"]{2,})$/i;
    return re.test(value) || 'E-mail inválido!';
}

export function min(value: any, min: number) {
    return value.length >= min || `Mínimo de ${min} caracteres!`;
}

export function comparePassword(value: any, other: any) {
    return value === other || 'Diferente da password';
}

export function dateToday(value: Date) {
    return new Date(value) < new Date() || 'Inserir uma data até ao dia de hoje!';
}

export function dateRange(value: Date, other: Date) {
    return new Date(value) < new Date(other) ? 'Inserir uma data de fim superior à data de início!' : true;
}

export function minNumber(value: number, min:number = 0) {
    return value < min ? `Valor mínimo de ${min}` : true;
}

export function maxNumber(value: number, max:number = 100) {
    return value > max ? `Valor máximo de ${max}` : true;
}

export default {
    required,
    email,
    min,
    comparePassword,
    dateToday,
    dateRange,
    minNumber,
    maxNumber
};