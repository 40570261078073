<template>
    <Top :notifications="!userStore.isTechnician" />
    <div class="tw-relative tw-mb-14 -tw-translate-y-0.5">
        <img class="tw-object-bottom tw-w-full tw-max-h-18 tw-object-cover" src="../assets/svg/header_profile.svg" alt="" />
        <img
            class="tw-rounded-full tw-aspect-square tw-object-cover tw-absolute tw-w-28 tw-bottom-0 tw-left-1/2 tw-translate-y-[40%] -tw-translate-x-1/2 tw-outline tw-outline-[0.3rem] tw-outline-primary tw-bg-gray_lightest"
            :src="picture.large"
            alt="" />
    </div>
    <v-container class="!tw-pb-0 !tw-pt-4">
        <h1 class="tw-text-2xl text-black tw-font-bold tw-text-center">{{ me.name }}</h1>
    </v-container>
    <v-container v-if="!userStore.isTechnician" class="!tw-py-2 tw-sticky tw-top-[var(--topBarHeight)] tw-z-10 tw-bg-white">
        <v-row>
            <v-col cols="6">
                <v-btn
                    @click="changeView('profile')"
                    block
                    variant="tonal"
                    size="large"
                    rounded="xl"
                    :class="activeComponentComp == 'profile' ? 'bg-primary_light' : 'bg-gray_light'"
                    class="text-none"
                    >Perfil</v-btn
                >
            </v-col>
            <v-col cols="6">
                <v-btn
                    v-if="!userStore.isTechnician"
                    @click="changeView('technicals')"
                    block
                    variant="tonal"
                    size="large"
                    rounded="xl"
                    :class="activeComponentComp == 'technicals' ? 'bg-primary_light' : 'bg-gray_light'"
                    class="text-none"
                    >Técnicos</v-btn
                >
            </v-col>
        </v-row>
    </v-container>
    <v-container class="!tw-py-4">
        <div v-if="activeComponentComp == 'profile'" class="tw-flex tw-flex-col tw-gap-4">
            <InputText disabled placeholder="Nome da organização" :modelValue="client.name" variant="solo" />
            <InputText disabled placeholder="NIF" :modelValue="client.nif" variant="solo" />
            <InputText disabled placeholder="Morada" :modelValue="client.address" variant="solo" />
            <InputText disabled placeholder="Email" :modelValue="me.email" variant="solo" />
            <InputText disabled placeholder="Telefone" :modelValue="client.phone" variant="solo" />
            <div class="d-flex">
                <v-btn class="mx-auto" @click="logout" width="110" type="submit" flat color="red">Logout</v-btn>
            </div>
            <div class="tw-mt-4">
                <p class="tw-text-center tw-text-gray_darker tw-text-sm">v{{ appVersion }}</p>
            </div>
        </div>
        <div v-if="activeComponentComp == 'technicals' && !userStore.isTechnician" class="tw-flex tw-flex-col tw-items-center tw-gap-4">
            <Card v-for="tech in me.client.users" :key="tech.id" :title="tech.name" :point="tech.email" :image="useMedia(tech.picture, 'user').large" :to="{ name: 'Technician', params: { id: tech.id } }" />
            <div v-if="me.client.users.length < me.client.max_technicians" class="tw-mt-8">
                <v-btn @click="goToCreateTechnician" variant="tonal" size="large" rounded="lg" class="bg-primary text-none">Criar Técnico</v-btn>
            </div>
        </div>
    </v-container>
</template>

<script setup lang="ts">
    import { computed, ref, onMounted } from 'vue';
    import Card from './Layout/Card.vue';
    import Top from './Layout/Top.vue';
    import InputText from './Layout/Forms/InputText.vue';
    import { useRouter } from 'vue-router';
    import { useUserStore } from '@/store/user';
    import axios from '@/plugins/axios';
    import useMedia from '@/composables/useMedia';
    import qs from 'qs';

    const $router = useRouter();
    const userStore = useUserStore();

    const appVersion = import.meta.env.VITE_APP_VERSION;

    let activeComponent = ref('profile');

    const me = ref({ client: { users: [] }, picture: null });

    const activeComponentComp = computed(() => {
        return activeComponent.value;
    });

    const changeView = (view: string) => {
        activeComponent.value = view;
    };

    const goToCreateTechnician = () => {
        $router.push({ name: 'CreateTechnician' });
    };

    const logout = async () => {
        userStore.$reset();
        $router.push({ name: 'Login' });
    };

    const query = ref({
        populate: {
            client: {
                populate: {
                    users: {
                        filters: {
                            role: {
                                name: {
                                    $eq: 'Technician',
                                },
                            },

                            id: {
                                $ne: userStore.getUser.id,
                            },
                        },
                        populate: {
                            picture: true,
                        },
                    },
                },
            },
            picture: true,
        },
    });

    const getMe = async () => {
        const res = await axios.get(`/users/me?${qs.stringify(query.value)}`);
        me.value = res.data;
    };

    const client = computed(() => {
        return me.value.client;
    });

    const picture = computed(() => {
        return useMedia(me.value.picture, 'user');
    });

    onMounted(() => {
        getMe();
    });
</script>

<style scoped></style>
