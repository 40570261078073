<template>
    <v-container v-if="props.readings.length > 0" class="flex-column justify-start -tw-translate-y-[6rem]">
        <v-row align="center" justify="center" class="fill-width h-auto justify-start align-content-start gap-4">
            <v-col cols="6" v-if="findReadingValue('ph')">
                <v-card class="bg-primary_lightest tw-aspect-square elevation-0 rounded-xl pa-4 d-flex flex-column justify-space-between">
                    <div class="tw-bg-primary_lighter tw-w-fit tw-rounded-full tw-p-2">
                        <v-icon size="24" color="primary">mdi-ph</v-icon>
                    </div>
                    <p :class="fontSizeValueClass" class="tw-text-center tw-font-medium">{{ findReadingValue('ph').value }}</p>
                    <p :class="fontSizeTitleClass" class="tw-text-center tw-text-gray_darker tw-leading-tight">pH</p>
                </v-card>
            </v-col>
            <v-col cols="6" v-if="findReadingValue('temperatura')">
                <v-card class="bg-primary_lightest tw-aspect-square elevation-0 rounded-xl pa-4 d-flex flex-column justify-space-between">
                    <div class="tw-bg-primary_lighter tw-w-fit tw-rounded-full tw-p-2 tw-items-center">
                        <v-icon size="24" color="primary">mdi-thermometer</v-icon><span class="tw-text-xs tw-font-medium tw-px-2">ºC</span>
                    </div>
                    <p :class="fontSizeValueClass" class="tw-text-center tw-font-medium">{{ findReadingValue('temperatura').value }}</p>
                    <p :class="fontSizeTitleClass" class="tw-text-center tw-text-gray_darker tw-leading-tight">Temperatura</p>
                </v-card>
            </v-col>
            <v-col cols="6" v-if="findReadingValue('cloro_total')">
                <v-card class="bg-primary_lightest tw-aspect-square elevation-0 rounded-xl pa-4 d-flex flex-column justify-space-between">
                    <div class="tw-bg-primary_lighter tw-w-fit tw-rounded-full tw-p-2 tw-flex tw-items-center">
                        <img class="tw-h-6" src="../../../assets/icons/cloro.svg" alt=""><span class="tw-text-xs  tw-font-medium tw-px-2 tw-inline-block">mg/l de Cl<sub>2</sub></span>
                    </div>
                    <p :class="fontSizeValueClass" class="tw-text-center tw-font-medium">{{ findReadingValue('cloro_total').value }}</p>
                    <p :class="fontSizeTitleClass" class="tw-text-center tw-text-gray_darker tw-leading-tight">Cloro total</p>

                </v-card>
            </v-col>
            <v-col cols="6" v-if="findReadingValue('cloro_combinado')">
                <v-card class="bg-primary_lightest tw-aspect-square elevation-0 rounded-xl pa-4 d-flex flex-column justify-space-between">
                    <div class="tw-bg-primary_lighter tw-w-fit tw-rounded-full tw-p-2 tw-flex tw-items-center">
                        <img class="tw-h-6" src="../../../assets/icons/cloro.svg" alt=""><span class="tw-text-xs xs:tw-text-xl tw-font-medium tw-px-2">mg/l de Cl<sub>2</sub></span>
                    </div>
                    <p :class="fontSizeValueClass" class="tw-text-center tw-font-medium">{{ findReadingValue('cloro_combinado').value }}</p>
                    <p :class="fontSizeTitleClass" class="tw-text-center tw-text-gray_darker tw-leading-tight">Cloro combinado</p>
                </v-card>
            </v-col>
            <v-col cols="12" v-if="findReadingValue('cloro_livre')">
                <v-card class="bg-primary_lightest tw-aspect-square elevation-0 rounded-xl pa-4 d-flex flex-column justify-space-between">
                    <div class="tw-bg-primary_lighter tw-w-fit tw-rounded-full tw-p-3 tw-flex tw-items-center">
                        <img class="tw-h-16" src="../../../assets/icons/cloro.svg" alt=""><span class="tw-text-2xl tw-font-medium tw-px-2">mg/l de Cl<sub>2</sub></span>
                    </div>
                    <p class="tw-text-6xl tw-text-center tw-font-medium">{{ findReadingValue('cloro_livre').value }}</p>
                    <p class="tw-text-4xl tw-text-center tw-text-gray_darker tw-leading-tight">Cloro livre</p>
                </v-card>
            </v-col>
            <v-col cols="6" v-if="findReadingValue('condutividade')">
                <v-card class="bg-primary_lightest tw-aspect-square elevation-0 rounded-xl pa-4 d-flex flex-column justify-space-between">
                    <div class="tw-bg-primary_lighter tw-w-fit tw-rounded-full tw-p-2">
                        <v-icon size="24" color="primary">mdi-lightning-bolt-outline</v-icon><span class="tw-text-xs xs:tw-text-xl tw-font-medium tw-px-2">µS/cm</span>
                    </div>
                    <p :class="fontSizeValueClass" class="tw-text-center tw-font-medium">{{ findReadingValue('condutividade').value }}</p>
                    <p :class="fontSizeTitleClass" class="tw-text-center tw-text-gray_darker tw-leading-tight">Condutividade</p>
                </v-card>
            </v-col>
            <v-col cols="6" v-if="findReadingValue('oxidabilidade')">
                <v-card class="bg-primary_lightest tw-aspect-square elevation-0 rounded-xl pa-4 d-flex flex-column justify-space-between">
                    <div class="tw-bg-primary_lighter tw-w-fit tw-rounded-full tw-p-2">
                        <v-icon size="24" color="primary">mdi-atom-variant</v-icon><span class="tw-text-xs xs:tw-text-xl tw-font-medium tw-px-2">mg/l de O<sub>2</sub></span>
                    </div>
                    <p :class="fontSizeValueClass" class="tw-text-center tw-font-medium">{{ findReadingValue('oxidabilidade').value }}</p>
                    <p :class="fontSizeTitleClass" class="tw-text-center tw-text-gray_darker tw-leading-tight">Oxidabilidade</p>
                </v-card>
            </v-col>
            <v-col cols="6" v-if="findReadingValue('amoniaco')">
                <v-card class="bg-primary_lightest tw-aspect-square elevation-0 rounded-xl pa-4 d-flex flex-column justify-space-between">
                    <div class="tw-bg-primary_lighter tw-w-fit tw-rounded-full tw-p-2">
                        <v-icon size="24" color="primary">mdi-bottle-tonic-outline</v-icon><span class="tw-text-xs xs:tw-text-xl tw-font-medium tw-px-2">mg/l</span>
                    </div>
                    <p :class="fontSizeValueClass" class="tw-text-center tw-font-medium">{{ findReadingValue('amoniaco').value }}</p>
                    <p :class="fontSizeTitleClass" class="tw-text-center tw-text-gray_darker tw-leading-tight">Amoniaco</p>
                </v-card>
            </v-col>
            <v-col cols="6" v-if="findReadingValue('coliformes_totais')">
                <v-card class="bg-primary_lightest tw-aspect-square elevation-0 rounded-xl pa-4 d-flex flex-column justify-space-between">
                    <div class="tw-bg-primary_lighter tw-w-fit tw-rounded-full tw-p-2">
                        <v-icon size="24" color="primary">mdi-bacteria-outline</v-icon><span class="tw-text-xs xs:tw-text-xl tw-font-medium tw-px-2">ufc/100mL</span>
                    </div>
                    <p :class="fontSizeValueClass" class="tw-text-center tw-font-medium">{{ findReadingValue('coliformes_totais').value }}</p>
                    <p :class="fontSizeTitleClass" class="tw-text-center tw-text-gray_darker tw-leading-tight">Coliformes totais</p>
                </v-card>
            </v-col>
            <v-col cols="6" v-if="findReadingValue('eachechia_coli')">
                <v-card class="bg-primary_lightest tw-aspect-square elevation-0 rounded-xl pa-4 d-flex flex-column justify-space-between">
                    <div class="tw-bg-primary_lighter tw-w-fit tw-rounded-full tw-p-2">
                        <v-icon size="24" color="primary">mdi-bacteria-outline</v-icon><span class="tw-text-xs xs:tw-text-xl tw-font-medium tw-px-2">ufc/100mL</span>
                    </div>
                    <p :class="fontSizeValueClass" class="tw-text-center tw-font-medium">{{ findReadingValue('eachechia_coli').value }}</p>
                    <p :class="fontSizeTitleClass" class="tw-text-center tw-text-gray_darker tw-leading-tight">Eachechia coli</p>
                </v-card>
            </v-col>
            <v-col cols="6" v-if="findReadingValue('enterecocos_fecais')">
                <v-card class="bg-primary_lightest tw-aspect-square elevation-0 rounded-xl pa-4 d-flex flex-column justify-space-between">
                    <div class="tw-bg-primary_lighter tw-w-fit tw-rounded-full tw-p-2">
                        <v-icon size="24" color="primary">mdi-bacteria-outline</v-icon><span class="tw-text-xs xs:tw-text-xl tw-font-medium tw-px-2">ufc/100mL</span>
                    </div>
                    <p :class="fontSizeValueClass" class="tw-text-center tw-font-medium">{{ findReadingValue('enterecocos_fecais').value }}</p>
                    <p :class="fontSizeTitleClass" class="tw-text-center tw-text-gray_darker tw-leading-tight">Enterecocos fecais</p>
                </v-card>
            </v-col>
            <v-col cols="6" v-if="findReadingValue('pseudomonas_aeruginosa')">
                <v-card class="bg-primary_lightest tw-aspect-square elevation-0 rounded-xl pa-4 d-flex flex-column justify-space-between">
                    <div class="tw-bg-primary_lighter tw-w-fit tw-rounded-full tw-p-2">
                        <v-icon size="24" color="primary">mdi-bacteria-outline</v-icon><span class="tw-text-xs xs:tw-text-xl tw-font-medium tw-px-2">ufc/100mL</span>
                    </div>
                    <p :class="fontSizeValueClass" class="tw-text-center tw-font-medium">{{ findReadingValue('pseudomonas_aeruginosa').value }}</p>
                    <p :class="fontSizeTitleClass" class="tw-text-center tw-text-gray_darker tw-leading-tight">Pseudomonas aeruginosa</p>
                </v-card>
            </v-col>
            <v-col cols="6" v-if="findReadingValue('staphylococcus')">
                <v-card class="bg-primary_lightest tw-aspect-square elevation-0 rounded-xl pa-4 d-flex flex-column justify-space-between">
                    <div class="tw-bg-primary_lighter tw-w-fit tw-rounded-full tw-p-2">
                        <v-icon size="24" color="primary">mdi-bacteria-outline</v-icon><span class="tw-text-xs xs:tw-text-xl tw-font-medium tw-px-2">ufc/100mL</span>
                    </div>
                    <p :class="fontSizeValueClass" class="tw-text-center tw-font-medium">{{ findReadingValue('staphylococcus').value }}</p>
                    <p :class="fontSizeTitleClass" class="tw-text-center tw-text-gray_darker tw-leading-tight">Staphylococcus</p>
                </v-card>
            </v-col>
            <v-col cols="6" v-if="findReadingValue('staphylococcus_produtor_de_coagulase')">
                <v-card class="bg-primary_lightest tw-aspect-square elevation-0 rounded-xl pa-4 d-flex flex-column justify-space-between">
                    <div class="tw-bg-primary_lighter tw-w-fit tw-rounded-full tw-p-2">
                        <v-icon size="24" color="primary">mdi-bacteria-outline</v-icon><span class="tw-text-xs xs:tw-text-xl tw-font-medium tw-px-2">ufc/100mL</span>
                    </div>
                    <p :class="fontSizeValueClass" class="tw-text-center tw-font-medium">{{ findReadingValue('staphylococcus_produtor_de_coagulase').value }}</p>
                    <p class="tw-text-center tw-text-sm tw-text-gray_darker tw-leading-tight">Staphylococcus produtor de coagulase</p>
                </v-card>
            </v-col>
            <v-col cols="6" v-if="findReadingValue('microrganismos_viaveis')">
                <v-card class="bg-primary_lightest tw-aspect-square elevation-0 rounded-xl pa-4 d-flex flex-column justify-space-between">
                    <div class="tw-bg-primary_lighter tw-w-fit tw-rounded-full tw-p-2">
                        <v-icon size="24" color="primary">mdi-bacteria-outline</v-icon><span class="tw-text-xs xs:tw-text-xl tw-font-medium tw-px-2">ufc/100mL</span>
                    </div>
                    <p :class="fontSizeValueClass" class="tw-text-center tw-font-medium">{{ findReadingValue('microrganismos_viaveis').value }}</p>
                    <p class="tw-text-center tw-text-sm tw-text-gray_darker tw-leading-tight">Microrganismos viáveis</p>
                </v-card>
            </v-col>

            <v-col cols="12" v-if="findReadingValue('legionella_pneumophila')">
                <v-card class="bg-primary_lightest elevation-0 rounded-xl pa-8 tw-h-40 d-flex tw-gap-4">

                    <div class="tw-bg-primary_lighter tw-self-center tw-rounded-full tw-flex tw-justify-center tw-items-center tw-h-20 tw-w-60">
                        <v-icon size="38" color="primary">mdi-bacteria-outline</v-icon><span class="tw-text-2xl tw-font-medium tw-px-2">ufc/l</span>
                    </div>
                    <div class="tw-self-center">
                        <p class="tw-text-4xl tw-font-medium">{{ findReadingValue('legionella_pneumophila').value }}</p>
                        <p :class="fontSizeTitleClass" class="tw-text-gray_darker tw-leading-tight tw-italic">Legionella Pneumophila</p>
                    </div>
                </v-card>
            </v-col>

            <v-col cols="12" v-if="findReadingValue('legionella_spp')">
                <v-card class="bg-primary_lightest elevation-0 rounded-xl pa-8 tw-h-40 d-flex tw-gap-4">

                    <div class="tw-bg-primary_lighter tw-self-center tw-rounded-full tw-flex tw-justify-center tw-items-center tw-h-20 tw-w-40">
                        <v-icon size="38" color="primary">mdi-bacteria-outline</v-icon><span class="tw-text-2xl tw-font-medium tw-px-2">ufc/l</span>
                    </div>
                    <div class="tw-self-center">
                        <p class="tw-text-4xl tw-font-medium">{{ findReadingValue('legionella_spp').value }}</p>
                        <p :class="fontSizeTitleClass" class="tw-text-gray_darker tw-leading-tight">Legionella spp</p>
                    </div>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script setup lang="ts">
    import { onMounted, ref } from 'vue';

    const props = defineProps({
        readings: {
            type: Array,
            required: true
        },
    });

    const findReadingValue = (value: String) => {
        const reading = props.readings.find((reading) => reading.field == value);
        if(!reading) return null;
        return reading;
    };

    const fontSizeValueClass = ref('tw-text-2xl');
    const fontSizeTitleClass = ref('tw-text-base');

    onMounted(() => {
        console.log(props.readings);

    });
</script>

<style scoped></style>
