<template>
    <div class="tw-mt-2 tw-relative tw-flex tw-items-center">
        <select
            :value="modelValue"
            @input="updateValue"
            class="tw-rounded-lg tw-focus:outline-none tw-focus:shadow-outline tw-leading-tight tw-shadow tw-text-gray-700 tw-w-full bg-gray_lightest tw-py-3 tw-px-6 tw-border tw-border-solid tw-border-gray_lightest">
            <!-- <option value="" selected disabled>Escolher infraestrutura</option>
            <option value="option1">OPTION1</option> -->
            <slot></slot>
        </select>
        <v-icon class="!tw-absolute tw-right-2" icon="mdi-chevron-down"></v-icon>
    </div>
</template>

<script setup lang="ts">
    const props = defineProps({
        modelValue: {
            type: String,
            default: '',
        },
    });

    const emit = defineEmits(['update:modelValue']);

    const updateValue = (event: any) => {
        emit('update:modelValue', event.target.value);
    };
</script>

<style scoped></style>
