import axios from '@/plugins/axios';
<template>
    <v-card @click="isCardOpen = !isCardOpen" color="primary_lightest" :class="{'danger': isDanger, 'inactive':notification.read}">
        <v-card-title :class="{ '!tw-whitespace-break-spaces': isCardOpen }" class="!tw-text-lg tw-text-primary">{{ notification.title }}</v-card-title>

        <v-card-text class="!tw-pb-0 !tw-mb-4" :class="{ '!tw-line-clamp-3': !isCardOpen }">
            {{ notification.text }}
        </v-card-text>

        <v-card-actions v-if="!notification.read" class="tw-flex-row tw-justify-between">
            <v-chip color="primary" class="!tw-font-semibold">Nova notificação</v-chip>
            <v-btn @click="markAsRead" color="primary"> Marcar como lida </v-btn>
        </v-card-actions>
    </v-card>
</template>

<script setup lang="ts">
    import axios from '@/plugins/axios';
    import { ref } from 'vue';
    import { computed } from 'vue';
    import _ from 'lodash';

    const emits = defineEmits(['getNotifications']);

    const isCardOpen = ref(false);

    const props = defineProps({
        notification: {
            type: Object,
            default: null,
        },
    });

    const isDanger = computed(() => {
        console.log(props.notification.danger);
        return _.get(props.notification, 'danger', false);
    });

    const markAsRead = async () => {
        await axios.put(`notifications/${props.notification.id}`, { data: { read: true } });
        emits('getNotifications');
    };
</script>

<style>
.danger {
    border-left: 6px solid red;
}
.inactive {
     /* add opacity */
    opacity: 0.5;
}
</style>
