<template>
    <Top :back="true" :edit="true" :notifications="false" />
    <div class="tw-relative tw-mb-14 -tw-translate-y-0.5">
        <img class="tw-object-bottom tw-w-full tw-max-h-18 tw-object-cover" src="../assets/svg/header_profile.svg" alt="" />
        <img
            class="tw-rounded-full tw-aspect-square tw-object-cover tw-absolute tw-w-28 tw-bottom-0 tw-left-1/2 tw-translate-y-[40%] -tw-translate-x-1/2 tw-outline tw-outline-[0.3rem] tw-outline-primary tw-bg-gray_lightest"
            :src="useMedia(tech.picture, 'user').large"
            alt="" />
    </div>
    <v-container class="flex-column justify-start tw-relative tw-flex tw-flex-1 !tw-pt-2" fluid>
        <v-row align="start" class="fill-width justify-start tw-bg-white !tw-flex-shrink-0 !tw-flex-grow-0" no-gutters>
            <v-col cols="12">
                <h1 class="tw-text-2xl text-black tw-font-bold tw-text-center">{{ tech.name }}</h1>
            </v-col>
            <v-col cols="12">
                <h1 class="text-black tw-font-bold tw-text-center">{{ tech.email }}</h1>
            </v-col>
        </v-row>
        <span class="tw-my-4 tw-font-medium tw-text-lg">Infraestruturas associadas</span>
        <div class="tw-relative tw-mb-8">
            <div class="tw-flex tw-flex-col tw-items-center tw-gap-4">
                <Card v-for="(infra, i) in infrastructures" :key="i" :title="infra.name" :image="useMedia(infra.picture, 'infrastructure').small" :arrow="false" />
            </div>
        </div>

        <v-dialog width="500">
            <template v-slot:activator="{ props }">
                <v-btn :loading="isLoading" v-bind="props" block rounded="lg" color="red">Eliminar técnico</v-btn>
            </template>

            <template v-slot:default="{ isActive }">
                <v-card title="Eliminar técnico">
                    <v-card-text>
                        <span class="tw-text-lg">Tem a certeza que pretende eliminar este técnico?</span>
                    </v-card-text>

                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                            text="Sim"
                            color="red"
                            @click="
                                isActive.value = false;
                                deleteTechnician();
                            "></v-btn>
                        <v-btn @click="isActive.value = false" color="primary" rounded="lg">Não</v-btn>
                    </v-card-actions>
                </v-card>
            </template>
        </v-dialog>
    </v-container>
</template>

<script setup lang="ts">
    import Card from './Layout/Card.vue';
    import Top from './Layout/Top.vue';
    import axios from '@/plugins/axios';
    import useMedia from '@/composables/useMedia';
    import { onMounted, ref, computed, watch } from 'vue';
    import { useRoute, useRouter } from 'vue-router';
    import qs from 'qs';
    import { useAppStore } from '@/store/app';

    const tech = ref({});
    const infrastructures = ref([]);
    const $route = useRoute();
    const $router = useRouter();
    const appStore = useAppStore();
    const isLoading = ref(false);

    const query = {
        populate: {
            picture: true,
        },
    };

    const getTechnician = async () => {
        const res = await axios.get(`/users/${$route.params.id}?${qs.stringify(query)}`);
        tech.value = res.data;
        console.log(res.data);
    };

    const getInfrastructures = async () => {
        const res = await axios.get(`/infrastructures?filters[client][users][id][$eq]=${$route.params.id}`);
        infrastructures.value = res.data.data;
        console.log(infrastructures.value);
    };

    async function deleteTechnician() {
        isLoading.value = true;
        try {
            await axios.delete(`/users/${$route.params.id}`);
            $router.push({ name: 'Profile' });
        } catch (error) {
            console.log(error);
        } finally {
            isLoading.value = false;
        }
    }

    watch(
        () => appStore.isEditing,
        (newValue) => {
            if (newValue) {
                $router.push({ name: 'EditTechnician', params: { id: $route.params.id } });
            }
        },
    );

    onMounted(async () => {
        await getTechnician();
        await getInfrastructures();
    });
</script>

<style scoped></style>
