// Utilities
import { defineStore } from 'pinia';

export const useUserStore = defineStore('user', {
    state: () => ({
        id: null,
        username: null,
        name: null,
        email: null,
        provider: null,
        confirmed: null,
        blocked: null,
        createdAt: null,
        updatedAt: null,
        token: null,
        role: {
            id: 0,
        },
        picture: null,
        client: null,
    }),
    getters: {
        getUser: (state) => {
            return {
                id: state.id,
                username: state.username,
                name: state.name,
                email: state.email,
                provider: state.provider,
                confirmed: state.confirmed,
                blocked: state.blocked,
                createdAt: state.createdAt,
                updatedAt: state.updatedAt,
                role: state.role,
                client: state.client,
            };
        },
        getToken: (state) => {
            return state.token;
        },
        isTechnician: (state) => {
            return state.role.name == "Technician";
        },
    },
    actions: {
        setUser(user: { id: number; username: string; name: string; email: string; provider: string; confirmed: boolean; blocked: boolean; createdAt: Date; updatedAt: Date; client: any }) {
            this.id = user.id;
            this.username = user.username;
            this.name = user.name;
            this.email = user.email;
            this.provider = user.provider;
            this.confirmed = user.confirmed;
            this.blocked = user.blocked;
            this.createdAt = user.createdAt;
            this.updatedAt = user.updatedAt;
            this.client = user.client;
            this.role = user.role;
            this.picture = user.picture;
        },
        setToken(token: string) {
            this.token = token;
        },
        logout() {
            this.id = null;
            this.username = null;
            this.name = null;
            this.email = null;
            this.provider = null;
            this.confirmed = null;
            this.blocked = null;
            this.createdAt = null;
            this.updatedAt = null;
            this.token = null;
            this.role = null;
            this.picture = null;
        },
    },
    persist: true,
});
