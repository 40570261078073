<template>
    <div>
        <label v-if="label" class="tw-text-black" for="">{{ label }}</label>
        <input
            v-if="variant == 'default'"
            :value="modelValue"
            @input="updateValue"
            :class="label ? 'tw-mt-2' : ''"
            class="tw-peeer tw-rounded-lg tw-focus:outline-none tw-focus:shadow-outline tw-leading-tight tw-shadow tw-text-gray-700 tw-w-full bg-gray_lightest tw-py-3 tw-px-6 tw-border tw-border-solid tw-border-gray_lightest"
            :type="type"
            :required="required"
            :readonly="disabled"
            :placeholder="placeholder"
            style="border-color: lightgray" />
        <input
            v-else-if="variant == 'solo'"
            :value="modelValue"
            @input="updateValue"
            color="gray"
            :class="size == 'small' ? 'tw-py-[0.75rem]' : ''"
            class="tw-rounded-lg tw-leading-tight tw-font-normal tw-placeholder-black tw-w-full bg-gray tw-py-4 tw-px-6 tw-border-gray_lightest"
            :type="type"
            :required="required"
            :readonly="disabled"
            :placeholder="placeholder" />
    </div>
</template>

<script setup lang="ts">
    const props = defineProps({
        modelValue: {
            type: String,
            default: '',
        },
        placeholder: {
            type: String,
            default: '',
        },
        label: {
            type: String,
            default: null,
        },
        type: {
            type: String,
            default: 'text',
        },
        variant: {
            type: String,
            default: 'default',
        },
        size: {
            type: String,
        },
        required: {
            type: Boolean,
            default: false,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
    });

    const emit = defineEmits(['update:modelValue']);

    const updateValue = (event: any) => {
        emit('update:modelValue', event.target.value);
    };
</script>

<style lang="scss" scoped></style>
